import mergeI18n from '~/utils/merge-i18n';
import dataI18n from '~/i18n/messages/fr-fr.contact-faq.json';

export default ({ primary, items }, pageData, { $i18n }) => {
  mergeI18n($i18n, dataI18n);
  return {
    title: primary.contact_faq_title,
    select: {
      name: 'contact-faq-select',
      label: $i18n.t('contact.faq-select-label'),
      type: 'select',
      value: '',
      options: [
        {
          value: '',
          label: $i18n.t('contact.faq-select-label'),
        },
        ...items
          .filter(
            ({ faq_item_label, smart_tribune_tag, smart_tribune_thematic }) =>
              faq_item_label && (smart_tribune_tag || smart_tribune_thematic)
          )
          .map(
            ({
              faq_item_label,
              smart_tribune_tag,
              smart_tribune_thematic,
            }) => ({
              value: faq_item_label.toLowerCase().split(' ').join('-'),
              label: faq_item_label,
              tag: smart_tribune_tag,
              thematic: smart_tribune_thematic,
            })
          ),
      ],
    },
    selectId: 'contact-faq-select',
    containerId: 'st-contact-form-select-container',
  };
};
